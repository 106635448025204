import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
// import { useHistory } from 'react-router-dom';
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Status } from "./status"
import { DataTables, useTable } from "../../components/DataTables"
import { Badge } from "reactstrap"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

function JobList() {
  //meta title
  document.title = "Jadwal"
  const navigate = useNavigate()
  const handleViewDetails = id => {
    // Navigate to page two with the id as a prop
    navigate(`/simulasi/${id}`)
    console.log(id)
  }
  const [addUrl, setUrl] = useState()
  const userStatus = row => {
    switch (row) {
      case "on_going":
        return <Badge className="bg-info">On Going</Badge>
      case "on_schedule":
        return <Badge className="bg-danger">On Schedule</Badge>
      case "ready to test":
        return <Badge className="bg-success">Ready</Badge>
      case "done":
        return <Badge className="bg-success">Done</Badge>
      case "selesai":
        return <Badge className="bg-success">Done</Badge>
    }
  }

  const userAction = row => {
    return (
      <Fragment>
        <div className="list-unstyled hstack gap-1 mb-0">
          <li data-bs-toggle="tooltip" data-bs-placement="top">
            <button
              className="btn btn-sm btn-soft-success"
              onClick={() => handleViewDetails(row)}
            >
              <i
                className=" mdi mdi-clipboard-pulse-outline"
                id={`detailtooltip-${row}`}
              ></i>
            </button>
          </li>
          <UncontrolledTooltip placement="top" target={`detailtooltip-${row}`}>
            Detail Jadwal
          </UncontrolledTooltip>
        </div>
      </Fragment>
    )
  }

  const columns = [
    {
      header: "Kode Test",
      type: "data",
      field: "mj_noreg",
    },
    {
      header: "Tanggal Mulai",
      type: "data",
      field: "mj_tglmulai",
    },
    {
      header: "Tanggal Selesai",
      type: "data",
      field: "mj_tglselesai",
    },
    {
      header: "Target Jabatan",
      type: "data",
      field: "target_nama",
    },
    {
      header: "Status",
      type: "status",
      field: "mj_status_jadwal",
    },
    {
      header: "Action",
      type: "action",
      field: "id_mjadwal",
      render: rowData => userAction(rowData),
    },
  ]

  const posts = useTable({ columns })

  const userData = JSON.parse(localStorage.getItem("authUser"))
  const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "Jadwal-Aktif"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Jadwal" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Jadwal</h5>
                  </div>
                </CardBody>
                <CardBody>
                  {userData.role.role != "Super Admin" ? (
                    <DataTables
                      model={posts}
                      stringUrl={baseURL}
                      userAction={userAction}
                      userStatus={userStatus}
                      addUrl={"&nip_penilai=" + userData.nip}
                    />
                  ) : (
                    <DataTables
                      model={posts}
                      stringUrl={baseURL}
                      userAction={userAction}
                      userStatus={userStatus}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobList
