import React from "react";
import "./index.scss";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { useEffect, useState, useRef } from "react";
import { resolvePagination } from "./resolve-pagination";
import { debounce } from 'lodash';

export const DatatablePagination = ({ model, onChangePage }) => {
  const [state, setState] = useState({
    currentPage: model.currentPage,
    pagination: {},
    length: 0,
  });

  useEffect(() => {
    state.currentPage = model.currentPage;
  }, [model.perPage]);

  useEffect(() => {
    const pagination = resolvePagination({
      perPage: model.perPage,
      totalRecord: model.totalRecord,
      currentPage: state.currentPage,
      size: model.size,
    });
    setState((state) => ({
      ...state,
      pagination,
      length: pagination.endPage - (pagination.startPage - 1),
      // currentPage: model.currentPage,
    }));
  }, [model.totalRecord, state.currentPage, model.perPage, model.currentPage]);

  const setCurrentPage = (currentPage) => {
    setState((state) => ({
      ...state,
      currentPage,
    }));
    if (onChangePage) {
      onChangePage(currentPage);
    }
  };

  const handleChangePage = (currentPage) => {
    if (currentPage < 1) {
      currentPage = 1;
    }
    if (state.currentPage === currentPage) return;
    setCurrentPage(currentPage);
  };

  const handleBackPage = () => {
    const currentPage = state.currentPage - 1;
    if (currentPage < state.pagination.firstPage) return;
    setCurrentPage(currentPage);
  };

  const handleNextPage = () => {
    const currentPage = state.currentPage + 1;
    if (currentPage > state.pagination.lastPage) return;
    setCurrentPage(currentPage);
  };

  const debounceRef = useRef(null);

  const onChangeInInput = event => {
    const page = event.target.value;
    if (page != '') {
      if (page < 1) {
        handleChangePage(1);
      } else if (page > state.pagination.endPage) {
        handleChangePage(state.pagination.endPage);
      } else {
        handleChangePage(page);
      }
    }
  };

  const debouncedOnChange = debounce(event => {
    onChangeInInput(event);
  }, 1000);

  const handleInputChange = event => {
    const value = event.target.value.trim();
    if (value === '') {
      setState((state) => ({
        ...state,
        currentPage: 1,
      }));
    } else {
      const intValue = parseInt(value);
      if (!isNaN(intValue)) {
        setState((state) => ({
          ...state,
          currentPage: Math.min(Math.max(1, intValue), state.pagination.endPage),
        }));
      }
    }
  };

  const handleInputKeyDown = event => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      // Backspace or Delete key
      setState((state) => ({
        ...state,
        currentPage: '',
      }));
    }
  };

  const handleInputBlur = event => {
    const value = event.target.value.trim();
    if (value === '') {
      setState((state) => ({
        ...state,
        currentPage: 1,
      }));
    } else {
      const intValue = parseInt(value);
      if (!isNaN(intValue)) {
        setState((state) => ({
          ...state,
          currentPage: Math.min(Math.max(1, intValue), state.pagination.endPage),
        }));
      }
    }
  };

  const handleInputOnChange = event => {
    const value = event.target.value.trim();
    if (value === '') {
      setState((state) => ({
        ...state,
        currentPage: 1,
      }));
    } else {
      const intValue = parseInt(value);
      if (!isNaN(intValue)) {
        setState((state) => ({
          ...state,
          currentPage: Math.min(Math.max(1, intValue), state.pagination.endPage),
        }));
      }
    }

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      onChangeInInput(event);
    }, 1000);
  };


  return (
    <React.Fragment>
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => handleChangePage(1)}
            // disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => handleBackPage()}

            // onClick={previousPage}
            // disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {state.currentPage} of {state.pagination.lastPage}
            {/* {pageIndex + 1} of {pageOptions.length} */}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={state.pagination.endPage}
            value={state.currentPage || ''}
            onChange={handleInputOnChange}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
          />

        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary"
              onClick={() => handleNextPage()}
            // onClick={nextPage}
            // disabled={!canNextPage}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => handleChangePage(state.pagination.lastPage)}
            // disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
