import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes, Pages404 } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import fakeBackend from "./helpers/AuthType/fakeBackend"

fakeBackend()


const getLayout = layoutType => {
  let Layout = VerticalLayout

  try {
    const userData = JSON.parse(localStorage.getItem("authUser"))
    const userRole = userData.role
    userRole ? Layout = VerticalLayout : Layout = HorizontalLayout;
    return Layout
  } catch (error) {
    switch (layoutType) {
      case layoutTypes.VERTICAL:
        Layout = VerticalLayout
        break
      case layoutTypes.HORIZONTAL:
        Layout = HorizontalLayout
        break
      default:
        break
    }
    return Layout
  }
}

const App = () => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const Layout = getLayout(layoutType)
  const userData = JSON.parse(localStorage.getItem("authUser"))
  try {
    if (!localStorage.getItem("authUser")) {
      return (
        <React.Fragment>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                }
                key={idx}
                exact={true}
              />
            ))}
            <Route path="*" element={<Pages404 />} />
          </Routes>
        </React.Fragment>
      )
    } else if (userData.role.role != null) {
      return (
        <React.Fragment>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <Layout>{route.component}</Layout>
                  </Authmiddleware>
                }
                key={idx}
                exact={true}
              />
            ))}
            <Route path="*" element={<Pages404 />} />
          </Routes>
        </React.Fragment>
      )
    }
  } catch (e) {
    return (
      <React.Fragment>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
          <Route path="*" element={<Pages404 />} />
        </Routes>
      </React.Fragment>
    )
  }
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
