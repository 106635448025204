import React, { Fragment, useState, useEffect } from "react"
import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    Table,
    Collapse,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap"
import { Link, useParams, useLocation } from "react-router-dom"


import classnames from "classnames"
import Swal from "sweetalert2";
import { Tab } from "bootstrap";

const Penilaian = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12} md={12} className="d-flex justify-content-start">
                        <Link
                            // to={"/ListSoal/" + id2}
                            className="btn btn-primary mb-4 rounded-3"
                        >
                            <i className="fa fa-arrow-left"></i> Kembali
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table table-striped table-bordered">
                                        <thead className="table-bordered">
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Kompetensi</th>
                                                <th scope="col">KeyB</th>
                                                <th scope="colgroup" colSpan={3}>Case Analysis (CA)</th>
                                                <th scope="colgroup" colSpan={3}>Problem Analysis (PA)</th>
                                                <th scope="colgroup" colSpan={2}>Case Analysis (CA)</th>
                                                <th scope="colgroup" colSpan={2}>Problem Analysis (PA)</th>
                                                <th scope="colgroup" colSpan={2}>Rekomendasi Sistem</th>
                                                <th scope="colgroup" colSpan={2}>Rekomendasi Integrasi</th>
                                                <th scope="col">Kesimpulan</th>
                                                <th scope="col">Saran Pengembangan Sistem</th>
                                                <th scope="col">Saran Pengembangan Manual</th>
                                            </tr>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col">Checklist</th>
                                                <th scope="col">Level</th>
                                                <th scope="col">Evidance</th>
                                                <th scope="col">Checklist</th>
                                                <th scope="col">Level</th>
                                                <th scope="col">Evidance</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Achievement</td>
                                                <td colSpan={2}>
                                                    <tr>
                                                        <td>
                                                            KeyB 1
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-4"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 2
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 3
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 4
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 5
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control">
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-4"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control">
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-2"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-2"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-2"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                        value="Bisa tulis manual atau generete by sistem"
                                                    />
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                        value="generete by sistem"
                                                    />
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                        value="Tulis Sesuatu"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Building Trust</td>
                                                <td colSpan={2}>
                                                    <tr>
                                                        <td>
                                                            KeyB 1
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-4"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 2
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 3
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 4
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            KeyB 5
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control">
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-4"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control">
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-2"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-2"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            0%
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center mx-2"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            <input
                                                                className="item-align-center"
                                                                type="checkbox"
                                                                value="Item 1"
                                                            // checked={items.includes('Item 1')}
                                                            // onChange={handleCheck}
                                                            />
                                                        </td>
                                                    </tr>
                                                </td>
                                                <td>
                                                    <select className="form-control" style={{ width: '30px' }}>
                                                        <option value="option1" selected={true}>1</option>
                                                        <option value="option2">2</option>
                                                        <option value="option3">3</option>
                                                        <option value="option4">4</option>
                                                        <option value="option4">5</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                        value="Bisa tulis manual atau generete by sistem"
                                                    />
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                        value="generete by sistem"
                                                    />
                                                </td>
                                                <td>
                                                    <textarea
                                                        className="form-control"
                                                        style={{ width: '150px' }}
                                                        value="Tulis Sesuatu"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Penilaian