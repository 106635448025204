import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ListSimulasi from "./ListSimulasi";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2";

function Simulasi(props) {

  //meta title
  document.title = "Simulai | BPKP";
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "materi?id_jadwal=" + id;
    Swal.fire({
      title: 'Loading',
      text: 'Please wait while we fetch the data...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    fetch(baseURL, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(responseData => {
        setData(responseData.data);
        setLoading(false);
        Swal.close();
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong! Please try again later.',
        });
      });
  }, []);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="ms-3">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Informasi Jadwal" />
          </div>
          <Row>
            {loading ? (
              <div>No Data...</div>
            ) : (
              <React.Fragment>
                <Col lg='12'>
                  <ListSimulasi dataSimulasi={data} id_jadwal={id} />
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Simulasi;
