import React from "react"
import { Navigate } from "react-router-dom"

// Simulasi
import Simulasi from "../pages/Simulasi/all-simulasi"

import ListSimulasi from "../pages/DetailSimulasi/DetailSimulasi"

// Jadwal
import Jadwal from "../pages/Jadwal/all-jadwal"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//monitoring

import Evidence from "../pages/Evidence/Evidence"
import Penilaian from "pages/Proses/Penilaian"

//auth
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Proses from "pages/Proses/Proses"
import Jumper from "pages/Authentication/Jumper"

import Pages404 from "components/Utility/Pages404"


const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },


  //File Manager
  { path: "/simulasi/:id", component: <Simulasi /> },

  //listsimulasi
  { path: "/simulasi/list/:id", component: <ListSimulasi /> },

  //jadwal

  { path: "/jadwal", component: <Jadwal /> },
  { path: "/evidence/:idSimulasi/:idJadwal/:idPeserta/:idSimulasiBatch", component: <Evidence /> },
  { path: "/proses", component: <Proses /> },
  { path: "/penilaian", component: <Penilaian /> },


  //profile
  // { path: "/Profile", component: <Profile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/jumper/:nip/:password/:idSimulasi/:idJadwal/:idPeserta/:idSimulasiBatch", component: <Jumper /> },

]
export { authProtectedRoutes, publicRoutes, Pages404 };

