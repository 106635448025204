import { useState } from "react"
import { usePagination } from '../Pagination'

export const useTable = ({ columns, records, perPage, totalRecord, currentPage }) => {
    const [state, setState] = useState({
        columns: columns || [],
        records: records || [],
        perPage: perPage || [],
        currentPage: currentPage || [],
    });

    const pagination = usePagination({ perPage, totalRecord })

    const setRecords = (records) => {
        setState((state) => ({
            ...state,
            records,
        }))
    }

    const setTotalRecord = (totalRecord) => {
        pagination.setTotalRecord(totalRecord);
    }

    const setPerPage = (perPage) => {
        pagination.setPerPage(perPage);
    }

    const setCurrentPage = (currentPage) => {
        pagination.setCurrentPage(currentPage);
    }

    return { ...state, setRecords, pagination, setTotalRecord, setPerPage, setCurrentPage };
};

