import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Row,
  Button
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/7309681.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const [zoom, setZoom] = useState(100);

  const [username, setusername] = useState();

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(userData.nip);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(userData.nip);
      }
    }
  }, [props.success]);

  function ZoomOut() {
    const zoomOut = zoom + 10;
    setZoom(zoomOut)
  }

  function ZoomIn() {
    const zoomIn = zoom - 10;
    setZoom(zoomIn)
  }

  useEffect(() => {
    document.body.style.zoom = zoom + "%";
  }, [zoom])

  // const cleanLocalStorage = () => {
  //   localStorage.removeItem('authUser'); // Replace 'yourKey' with the actual key
  // };

  // // Add beforeunload event listener
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     cleanLocalStorage();
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Clean up event listener when component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <React.Fragment>
      <div className="dropdown-divider" />
      <Button onClick={() => ZoomOut()} className="dropdown-item">
        <i className="bx bx-plus font-size-16 me-1 text-light" />
      </Button>
      <Button onClick={() => ZoomIn()} className="dropdown-item">
        <i className="bx bx-minus font-size-16 me-1 text-light" />
      </Button>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-1 text-white">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
