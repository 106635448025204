import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { Link } from "react-router-dom"

import classNames from "classnames"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

import verification from "../../assets/images/verification-img.png";

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
  deleteAllEvents
} from "../../store/actions";

import DeleteModal from "./DeleteModal";

//css
import "@fullcalendar/bootstrap/main.css";

import Swal from "sweetalert2";


const Dashboard = props => {

  //meta title
  document.title = "Dashboard | BPKP"

  const dispatch = useDispatch();

  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(true)
  const [dataJadwal, setDataJadwal] = useState([]);
  const [onGoing, setOnGoing] = useState(0)
  const [onSchedule, setOnSchedule] = useState(0)
  const [readyToTest, setReadyToTest] = useState(0)
  const userData = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    setLoading(true);
    let baseURL = '';
    dispatch(deleteAllEvents());
    if (userData.role.role != "Super Admin") {
      baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "Jadwal-Aktif?nip_penilai=" + userData.nip;
    } else {
      baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "Jadwal-Aktif";
    }

    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          const dataJadwal = responseData.data[0].data;
          setDataJadwal(dataJadwal);
          setLoading(false);
          Swal.close() // Call the function for each element
        } else {
          setLoading(false);
          Swal.close()
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseData.meta.message,
          }).then(() => {
          });
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        Swal.close()
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        });
      });
  }, []);

  useEffect(() => {
    if (dataJadwal) {
      let onGoingCount = 0;
      let onScheduleCount = 0;
      let readyToTestCount = 0;

      dataJadwal.forEach((item) => {
        let bg = "";

        if (item.mj_status_jadwal === "on_going") {
          bg = "bg-info";
          onGoingCount++;
        } else if (item.mj_status_jadwal === "on_schedule") {
          bg = "bg-danger";
          onScheduleCount++;
        } else if (item.mj_status_jadwal === "ready to test") {
          bg = "bg-success";
          readyToTestCount++;
        } else if (item.mj_status_jadwal === "done") {
          bg = "bg-success";
          onScheduleCount++;
        }

        const newEvent = {
          id: item.id_mjadwal,
          title: item.target_nama,
          start: item.mj_tglmulai,
          end: item.mj_tglselesai,
          className: bg + ' text-white',
          noreg: item.mj_noreg,
          status: item.mj_status_jadwal,
        };
        dispatch(onAddNewEvent(newEvent));
      });
      setOnGoing(onGoingCount)
      setOnSchedule(onScheduleCount)
      setReadyToTest(readyToTestCount)
    }
  }, [dataJadwal])




  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || '',
      category: (event && event.category) || 'bg-danger',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        };
        // update event
        dispatch(onUpdateEvent(updateEvent));
        validation.resetForm();
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        };
        // save new event
        dispatch(onAddNewEvent(newEvent));
        validation.resetForm();
      }
      setSelectedDay(null);
      toggle();
    },
  });



  const { events, categories } = useSelector(state => ({
    events: state.calendar.events,
    categories: state.calendar.categories,
  }));

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalcategory, setModalcategory] = useState(false);

  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modal, event]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
    } else {
      setModal(true);
    }
  };

  const toggleCategory = () => {
    setModalcategory(!modalcategory);
  };

  const handleEventClick = arg => {
    const event = arg.event;
    console.log(event)
    setEvent({
      id: event.id,
      title: event.title,
      title_category: event.title_category,
      start: event.start,
      end: event.end,
      noreg: event.extendedProps.noreg,
      status: event.extendedProps.status,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    });
    setIsEdit(true);
    toggle();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Dashboards"
          />
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">On Going</p>
                          <h4 className="mb-0">{onGoing}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + 0 + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Done</p>
                          <h4 className="mb-0">{onSchedule}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + 0 + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Ready to Test</p>
                          <h4 className="mb-0">{readyToTest}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + 0 + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <DeleteModal
                    show={deleteModal}
                    // onDeleteClick={handleDeleteEvent}
                    onCloseClick={() => setDeleteModal(false)}
                  />
                  <div className="page-content">
                    <Container fluid={true}>
                      <Row>
                        <Col className="col-12">
                          <Row>
                            <Col lg={12}>
                              {/* fullcalendar control */}
                              <FullCalendar
                                plugins={[
                                  BootstrapTheme,
                                  dayGridPlugin,
                                  // interactionPlugin,
                                ]}
                                slotDuration={"00:15:00"}
                                handleWindowResize={true}
                                themeSystem="bootstrap"
                                headerToolbar={{
                                  left: "prev,next today",
                                  center: "title",
                                  right: "dayGridMonth,dayGridWeek,dayGridDay",
                                }}
                                events={events}
                                editable={true}
                                droppable={false}
                                selectable={true}
                                // dateClick={handleDateClick}
                                eventClick={handleEventClick}
                              // drop={onDrop}
                              />

                              {/* New/Edit event modal */}
                              <Modal
                                isOpen={modal}
                                className={props.className}
                                centered
                              >
                                <ModalHeader toggle={toggle} tag="h5" className="py-3 px-4 border-bottom-0">
                                  Detail Jadwal
                                </ModalHeader>
                                <ModalBody className="p-4">
                                  <Form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      validation.handleSubmit();
                                      return false;
                                    }}
                                  >
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">Nama Jadwal</Label>
                                          <Input
                                            name="title"
                                            type="text"
                                            // value={event ? event.title : ""}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.title || ""}
                                            invalid={
                                              validation.touched.title && validation.errors.title ? true : false
                                            }
                                            disabled={true}
                                          />
                                          {validation.touched.title && validation.errors.title ? (
                                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">Tanggal Mulai</Label>
                                          <Input
                                            name="title"
                                            type="text"
                                            // value={event ? event.title : ""}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={event ? event.start : ""}
                                            invalid={
                                              validation.touched.title && validation.errors.title ? true : false
                                            }
                                            disabled={true}
                                          />
                                          {validation.touched.title && validation.errors.title ? (
                                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">Tanggal Selesai</Label>
                                          <Input
                                            name="title"
                                            type="text"
                                            // value={event ? event.title : ""}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={event ? event.end : ""}
                                            invalid={
                                              validation.touched.title && validation.errors.title ? true : false
                                            }
                                            disabled={true}
                                          />
                                          {validation.touched.title && validation.errors.title ? (
                                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">Noreg</Label>
                                          <Input
                                            name="title"
                                            type="text"
                                            // value={event ? event.title : ""}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={event ? event.noreg : ""}
                                            invalid={
                                              validation.touched.title && validation.errors.title ? true : false
                                            }
                                            disabled={true}
                                          />
                                          {validation.touched.title && validation.errors.title ? (
                                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label className="form-label">Status</Label>
                                          <Input
                                            name="title"
                                            type="text"
                                            // value={event ? event.title : ""}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={event ? event.status : ""}
                                            invalid={
                                              validation.touched.title && validation.errors.title ? true : false
                                            }
                                            disabled={true}
                                          />
                                          {validation.touched.title && validation.errors.title ? (
                                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                          ) : null}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default withTranslation()(Dashboard)
