import React, { useEffect, useMemo, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
} from "reactstrap";
import DetailSimulasi from '../DetailSimulasi/DetailSimulasi';
const ListSimulasi = (props) => {
  const data = props.dataSimulasi;
  const id_jadwal = props.id_jadwal;
  const [accordionOpen, setAccordionOpen] = useState([]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleAccordion = (index) => {
    const updatedAccordionOpen = [...accordionOpen];
    updatedAccordionOpen[index] = !updatedAccordionOpen[index];
    setAccordionOpen(updatedAccordionOpen);
  };

  console.log(data[0]);
  return (
    <React.Fragment>
      <div>
        {data.length > 0 ? (
          data[0].simulasi.map((item, index) => (
            <Card key={item.id_mjadwal_simulasi}>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id={`heading-${index}`}
                >
                  <CardTitle className="bg-white p-3 rounded text-black">
                    <Row className="mb-3">
                      <Col>
                        <span
                          className="bg-primary rounded text-white p-1"
                          style={{ fontSize: 10 }}
                        >
                          {data[0].jadwal[0].mj_tglmulai} - {data[0].jadwal[0].mj_tglselesai}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col col={10} className="text-start">
                        {item.mjs_simulasi}
                      </Col>
                      <Col col={2} className="text-end">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          style={{ cursor: "pointer" }}
                        >
                          Detail
                        </button>
                      </Col>
                    </Row>
                  </CardTitle>
                </h2>

                <Collapse
                  isOpen={accordionOpen[index]}
                  className="accordion-collapse collapse"
                  id={`collapse-${index}`}
                  aria-labelledby={`heading-${index}`}
                >
                  <div className="accordion-body">
                    <CardBody>
                      <Row>
                        <Col>
                          {accordionOpen[index] === true && (
                            <DetailSimulasi data={item.mjs_id_simulasi} id_jadwal={id_jadwal} />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </div>
                </Collapse>
              </div>
            </Card>
          ))
        ) : (
          <div>No Data...</div>
        )}
      </div>
    </React.Fragment >
  );
};

export default ListSimulasi;
