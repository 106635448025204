import React from 'react'
import { Navigate, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

const Jumper = () => {
    const { nip, password, idSimulasi, idJadwal, idPeserta, idSimulasiBatch } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    if (location.pathname === '/jumper') {
        if (localStorage.getItem("authUser")) {
            localStorage.removeItem('authUser');
        }
    }

    Swal.fire({
        title: "Redirecting...",
        text: "Please wait while we process your request",
        showConfirmButton: false,
        onBeforeOpen: () => {
            Swal.showLoading();
        },
    });

    const formdata = new FormData();
    formdata.append("nip", nip);
    formdata.append("password", password);
    formdata.append("idSimulasi", idSimulasi);
    formdata.append("idPeserta", idPeserta);
    formdata.append("idJadwal", idJadwal);
    formdata.append("idBatch", idSimulasiBatch);

    const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };
    const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "auth/jumper-login"
    fetch(baseURL, requestOptions)
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            if (data.meta.code == 200) {
                localStorage.setItem('access_token', data.data.access_token)
                localStorage.setItem('authUser', JSON.stringify(data.data.user));
                if (data.data.user.role) {
                    Swal.fire({
                        title: "Success!",
                        text: "You have been redirected.",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000
                    }).then(() => {
                        // Do something after the Swal is closed, e.g. redirect to dashboard
                        navigate(`/evidence/${data.data.user.id_simulasi}/${data.data.user.id_jadwal}/${data.data.user.id_peserta}/${data.data.user.id_batch}`);
                    });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "Encounter Error",
                        icon: "error",
                    });
                }
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.data.error,
                    icon: "error",
                });
            }
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: "Error",
                text: "An error occurred while redirecting. Please try again later.",
                icon: "error",
            });
        });
}

export default Jumper