import React, { Fragment, useState, useEffect } from "react"
import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    Table,
    Collapse,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap"
import { Link, useParams, useLocation } from "react-router-dom"


import classnames from "classnames"
import Swal from "sweetalert2";
import { Tab } from "bootstrap";

const Proses = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12} md={12} className="d-flex justify-content-start">
                        <Link
                            // to={"/ListSoal/" + id2}
                            className="btn btn-primary mb-4 rounded-3"
                        >
                            <i className="fa fa-arrow-left"></i> Kembali
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="rounded" style={{ border: '1px solid black' }}>
                                Assessor Meeting
                            </CardHeader>
                            <CardBody>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>No Regristasi</th>
                                            <th>:</th>
                                            <td>TES1239</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Mulai</th>
                                            <th>:</th>
                                            <td>2023-03-28</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Selesai</th>
                                            <th>:</th>
                                            <td>2023-04-05</td>
                                        </tr>
                                        <tr>
                                            <th>Target Jabatan</th>
                                            <th>:</th>
                                            <td>Eselon 2 Keuangan</td>
                                        </tr>
                                        <tr>
                                            <th>Instansi</th>
                                            <th>:</th>
                                            <td>Smartsoft</td>
                                        </tr>
                                        <tr>
                                            <th>Lokasi</th>
                                            <th>:</th>
                                            <td>Malang</td>
                                        </tr>
                                        <tr>
                                            <th>PIC</th>
                                            <th>:</th>
                                            <td>John Doe</td>
                                        </tr>
                                        <tr>
                                            <th>Assesor</th>
                                            <th>:</th>
                                            <td>
                                                Assesor_ardiana <br />
                                                Assesor_Retno
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Col>
                                    <Row xl={6} className="align-item-start justify-content-start mt-2 mb-2">
                                        <Button className="btn btn-primary">Data Integrasi</Button>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Table className="table table-striped table-bordered">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">NIP</th>
                                                    <th scope="col">Nama</th>
                                                    <th scope="col">Proses</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>000221212</td>
                                                    <td>Alex Martin</td>
                                                    <td>
                                                        - Case Analysis (CA) <br />
                                                        - Problem Analysis (PA)
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "red" }}>
                                                            Proses Asesor
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary">
                                                            Proses
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>000221212</td>
                                                    <td>Alex Martin</td>
                                                    <td>
                                                        - Case Analysis (CA)
                                                    </td>
                                                    <td>
                                                        <p style={{ color: "red" }}>
                                                            Proses Asesor
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary">
                                                            Proses
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Proses