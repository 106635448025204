import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//redux
import { useSelector, useDispatch } from "react-redux"
import { DataTables, useTable } from "../../components/DataTables"
import { Badge, Button } from 'reactstrap';
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

function JobList(props) {
  //meta title
  document.title = "Jadawal"

  const [modal, setModal] = useState(false)
  const [idModal, setIdModal] = useState([0])
  const [dataModal, setDataModal] = useState([])
  const [loading, setLoading] = useState(false)
  const id_simulasi = props.data;
  const id_jadwal = props.idJadwal;
  const navigate = useNavigate()

  // validation

  const toggle = (props) => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const detailModal = (id) => {
    setIdModal(id);
    toggle();
  }

  const evidence = (row) => {
    navigate(`/evidence/${props.data}/${props.id_jadwal}/${row[1]}/${row[0]}`);
  }

  const userAction = (row) => {

    return (
      <div>
        <Button
          className="btn btn-info rounded"
          type="button"
          onClick={() => evidence(row)}
          style={{ cursor: "pointer" }}
        >
          Isi Evidence
        </Button>
      </div>
    )
  };

  const userStatus = (row) => {
    switch (row) {
      case "proses":
        return <Badge className="bg-info">PROSES</Badge>
      case "Belum Dikerjakan":
        return <Badge className="bg-danger">BELUM DIKERJAKAN</Badge>
      case "selesai":
        return <Badge className="bg-success">SELESAI</Badge>
    }
  }
  const columns = [
    {
      header: "Nama",
      type: "data",
      field: "nama_peserta",
    },
    {
      header: "Instansi",
      type: "data",
      field: "nama_instansi",
    },
    {
      header: "Jabatan",
      type: "data",
      field: "jabatan",
    },
    {
      header: "Status",
      type: "status",
      field: "status",
    },
    {
      header: "Action",
      type: "action",
      field: ["id_mjasesi_batch", "ja_id_peserta"],
    },
  ]
  const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "Detail-simulasi"
  const posts = useTable({ columns });
  const addUrl = "&id_simulasi=" + props.data + "&id_jadwal=" + props.id_jadwal;


  return (
    <React.Fragment>
      <div>
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <DataTables model={posts} stringUrl={baseURL} userAction={userAction} userStatus={userStatus} addUrl={addUrl} />
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Informasi Peserta
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="table-responsive">
                    <div>
                      {dataModal && loading == false ? (
                        <Table className="table mb-0">
                          <tbody>
                            <tr>
                              <th>NIP</th>
                              <th>:</th>
                              <td>{dataModal.nip}</td>
                            </tr>
                            <tr>
                              <th>Nama</th>
                              <th>:</th>
                              <td>{dataModal.nama_peserta}</td>
                            </tr>
                            <tr>
                              <th>Instansi</th>
                              <th>:</th>
                              <td>{dataModal.instansi?.nama_instansi}</td>
                            </tr>
                            <tr>
                              <th>Jabatan</th>
                              <th>:</th>
                              <td>{dataModal.jabatan}</td>
                            </tr>
                            <tr>
                              <th>Pendidikan</th>
                              <th>:</th>
                              <td>{dataModal.pendidikan}</td>
                            </tr>
                            <tr>
                              <th>Umur</th>
                              <th>:</th>
                              <td>{dataModal.umur}</td>
                            </tr>
                            <tr>
                              <th>Status</th>
                              <th>:</th>
                              <td>{dataModal.status}</td>
                            </tr>
                            <tr>
                              <th>Tempat, Tanggal Lahir</th>
                              <th>:</th>
                              <td>{dataModal.tempat_lahir}, {dataModal.tanggal_lahir}</td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobList
